/* -----------------------------------------------
// Pure CSS variables -- temporary
// ----------------------------------------------- */

/* Palettes */
:root {
    /* Greyscale */
    --grey:          	  hsl(231, 10%, 59%);
    --grey-dark:     	  hsl(231, 10%, 44%);
    --grey-darker:   	  hsl(231, 10%, 29%);
    --grey-darkest:   	  hsl(231, 10%, 14%);
    --grey-light:    	  hsl(231, 10%, 79%);
    --grey-lighter:  	  hsl(231, 10%, 87%);
    --grey-lightest: 	  hsl(231, 10%, 96%);

    /* Black & White */
    --black:         	  hsl(0, 0%, 0%);
    --white:         	  hsl(0, 0%, 100%);
    --off-shite:    	  hsl(231, 10%, 99%);

    /* primary colors */
    --primary:          	hsl(221, 68%, 57%);
    --primary-dark:     	hsl(221, 68%, 42%);
    --primary-darker:   	hsl(221, 68%, 27%);
    --primary-darkest:  	hsl(221, 68%, 12%);
    --primary-light:    	hsl(221, 68%, 72%);
    --primary-lighter:  	hsl(221, 68%, 82%);
    --primary-lightest: 	hsl(221, 68%, 92%);

    /* Positive colors */
    --positive:          hsl(119, 41%, 53%);
    --positive-dark:     hsl(119, 41%, 38%);
    --positive-darker:   hsl(119, 41%, 23%);
    --positive-darkest:  hsl(119, 41%, 8%);
    --positive-light:    hsl(119, 41%, 63%);
    --positive-lighter:  hsl(119, 41%, 73%);
    --positive-lightest: hsl(119, 41%, 83%);


    /* Negative colors */
    --negative:          hsl(3, 67%, 58%);
    --negative-dark:     hsl(3, 67%, 43%);
    --negative-darker:   hsl(3, 67%, 28%);
    --negative-darkest:  hsl(3, 67%, 13%);
    --negative-light:    hsl(3, 67%, 68%);
    --negative-lighter:  hsl(3, 67%, 78%);
    --negative-lightest: hsl(3, 67%, 88%);

    /* Warning colors */
    --warning:          hsl(37, 75%, 59%);
    --warning-dark:     hsl(37, 75%, 44%);
    --warning-darker:   hsl(37, 75%, 29%);
    --warning-darkest:  hsl(37, 75%, 14%);
    --warning-light:    hsl(37, 75%, 69%);
    --warning-lighter:  hsl(37, 75%, 79%);
    --warning-lightest: hsl(37, 75%, 89%);

    /* Support colors */
    --support:           hsl(221, 68%, 57%);
    --support-dark:      hsl(221, 68%, 42%);
    --support-darker:    hsl(221, 68%, 27%);

    /* Theme colors */
    --theme-text:             hsl(0, 0%, 100%);

    --theme-primary:          hsl(255, 30%, 55%);
    --theme-primary-dark:     hsl(255, 30%, 45%);
    --theme-primary-rgb:      123, 106, 175;

    --theme-secondary:        hsl(255, 30%, 65%);
}

/* Typography */
:root {
    /* Font Family */
    --family-primary:   'Fira Sans', -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
    --family-secondary: 'Roboto', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --family-code:      'Fira Code', monospace;
    --render-mode:      optimizeLegibility;

    /* Font sizes */
    --size-3xsmall:  10px;
    --size-xxsmall:  11px;
    --size-xsmall:   12px;
    --size-small:    13px;
    --size-medium:   14px;
    --size-large:    16px;
    --size-xlarge:   18px;
    --size-xxlarge:  24px;
    --size-3xlarge:  32px;

    /* Font weights */
    --weight-light:    300;
    --weight-normal:   400;
    --weight-semibold: 500;
    --weight-bold:     700;
}

/* Layout */
:root {
    /* Border radius */
    --radius-small:    3px;
    --radius-medium:   6px;
    --radius-large:    12px;

    /* Spacing */
    --spacer-xsmall:   4px;
    --spacer-small:    8px;
    --spacer-medium:   12px;
    --spacer-large:    16px;
    --spacer-xlarge:   24px;
    --spacer-xxlarge:  32px;

    /* Form heights */
    --form-xsmall:     26px;
    --form-small:      30px;
    --form-medium:     36px;

    /* Shadows depth */
    --shadow-low:      2px 2px 4px 0 hsl(var(--grey-lighter), 0.5);
    --shadow-high:     4px 4px 14px 0 hsl(var(--grey-lighter), 0.5);
}
