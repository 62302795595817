@import '/src/core/scss/all.scss';

$workspaceLoadingPattern: url('~components/WorkspaceLoading/WorkspaceLoadingPattern.svg');

div#WorkspaceLoading {
    min-height: 700px;
}

.WorkspaceLoadingPattern {
    height: 213px;
    width: 498px;
    position: fixed;
    background-size: auto;
    background-repeat: no-repeat;
    background-image: $workspaceLoadingPattern;
    z-index: 1002;

    &--top {
        top: 0;
        right: 0;
      }
    
      &--bottom {
        bottom: 0;
        left: 0;
        transform: rotate(180deg);
      }
}

.WorkspaceLoadingSpinner {
    background-color: $white;
    position: fixed;
    z-index: 1000;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: $grey-darkest;

    img {
        height: 100px;
        width: 100px;
        margin-top: 35vh;
        margin-bottom: 10px;
    }

    h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}
