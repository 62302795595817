// Fonts
$grey-darkest: #2B2B2F;
$grey-dark: #616374;
$grey: #9496AB;
$grey-light: #C5C4D6;
$grey-lighter: #E8EBF3;
$grey-lightest: #F5F6FA;

// Borders
$grey-border-dark: #65687b;
$grey-border: #cdced6;
$grey-border-light: #dbdce1;
$grey-border-lightest: #e8ebf3;

// Backgrounds
$grey-bg: #eeeff1;

// Branding
$purple: #7866ac;
$primary: #4575DC;
$primary-light: #ECF1FC;


// Neutrals
$white: #FFFFFF;

// Box-shadow
$tile-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
$tile-shadow-hover: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
$nav-bar-shadow: 0 0 10px 6px rgba(0,0,0,.1);
$button-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03);
$side-panel-shadow: 4px 0px 15px 0px rgba(0, 0, 0, 0.20);

// Wash
$wash: rgba(0, 0, 0, 0.50);
