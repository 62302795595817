@import "/src/core/scss/all.scss";

.MobileNavigationWrapper {
    width: 100%;
    font-size: $font-size-body-mobile;
    display: flex; /* Use flex display */
    padding-left: 28px;
    justify-content: space-between;
}

.MobileNavigationWrapper .Button--topbar {
    flex-grow: 1;
    max-width: 250px;
    min-width: 125px;
}

.MobileDropdown {
    width: 100%;
    max-height: 135px;

    &--title {
        font-size: 10px;
        font-weight: 500;
    }

    .Dropdown {
        width: 100%;
        padding-right: 12px;

        .Button {
            height: 32px !important;
            border-radius: 3px;

            &--topbar {
                font-size: 10px;
                font-weight: 500;
            }
        }

        .MenuItem {
            font-size: 10px !important;
            font-weight: 500;
        }
    }
}

.MobileMenu {
    z-index: 1002;

    &.Dropdown--topbar {
        .mobile-nav-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                width: 100%;

                li {
                    cursor: pointer;
                    padding: 10px;
                    color: inherit;
                    display: block;
                    width: 100%;

                    &.active {
                        background: $primary-light;
                        color: $primary;
                        cursor: pointer;

                        &:after {
                            content: "";
                            height: 12px;
                            width: 25px;
                            background: url("/images/icons/arrow-right.svg") no-repeat;
                            margin-left: auto;
                        }

                        a {
                            color: $primary;
                        }
                    }

                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .Button--topbar {
        width: 100%;
        color: #616374;
        font-weight: 500;
        font-size: $font-size-body-mobile;
        border: 1px solid #dbdce1;
        border-radius: 6px;
    }
}

.Button--topbar {
    width: 100%;
}

.gd-mobile-dropdown-overlay {
    padding-top: 120px !important;
}

.gd-mobile-dropdown-content {
    max-height: calc(100vh - 120px);
}

.gd-mobile-dropdown-content .gd-is-mobile .gd-invertable-select-search-bar {
    padding-right: 20px;
}

.gd-extended-date-filter-actions {
    margin: 0px !important;
}

@media (max-width: $mobile) {
    .MobileMenu svg
    {
        margin-left: var(--spacer-small);
        width: 8px !important;
        display: flex;
        align-items: center;
    }

    .MobileDropdown .Dropdown {
        padding-right: 0px;
    }
}
