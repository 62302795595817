@import 'metroplex/dist/base/styles/variables/_variables';
@import 'variables/variables.css';

.Frame {
    height: 100%;
    min-height: calc(100vh);
    padding-top: 241px;
    background-color: $white;

    &--iframe {
        padding-top: 0;

        .App {
            top: 0;
        }

        .left-menu {
            display: none;
        }

        .PageHeader {
            display: none;
        }

        .Topbar {
            display: none;
        }

        .dashboard {
            padding: 0;
        }
    }
}

.PageSpinner {
    background-color: $white;
    position: fixed;
    z-index: 1000;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #666;

    svg {
        fill: $primary;
        height: 90px;
        width: 90px;
        margin-top: 35vh;
        margin-bottom: 5px;
        animation: ball-rotate-animation 1s cubic-bezier(0.7, -0.13, 0.22, 0.86) infinite;
    }
}

.toast {
    z-index: 1002;
}

@keyframes ball-rotate-animation {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
