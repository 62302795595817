@import '/src/core/scss/all';

.workspace-container {
    display: flex;
    width: 100%;
    margin-top: 10px;
}

.dashboard {
    width: 100%;
    padding: 38px 48px 38px 264px;

    .DashboardLoadingSpinner {
        width: calc(100% - 264px);
        background-color: $white;
        position: fixed;
        z-index: 500;
        text-align: center;
        color: $grey-darkest;
    
        img {
            height: 100px;
            width: 100px;
            margin-bottom: 10px;
            margin-top: 70px;
        }
    
        h2 {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
        }
    
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }
}

@media (max-width: $mobile)
{
    .workspace-container {
        width: 105%;
        align-items: flex-end;
    }

    .dashboard {
        height: 40px;
        padding: 0;
        margin-left: auto;

        &--loading {
            width: 100%;
        }

        .DashboardLoadingSpinner {
            width: 100%;
        }
    }
}
