@import '/src/core/scss/all.scss';

.DashboardContainer {
    z-index: 1000;
}

.dashboard {
    display: flex;
    flex-direction: column;
    flex: 1;

    .dash-header .dash-header-options-button {
        height: 32px !important;
    }

    .dash-header {
        border-bottom: none;
        align-items: initial;
    }

    .dash-header-inner {
        margin-left: 0;
    }

    .gd-locked-status {
        display: none;
    }

    .dash-title {
        font-family: $font !important;
        font-size: 20px;
        font-weight: 400;
        color: var(--grey-darker);
        line-height: 20px;
        padding-top: 0;
        padding-left: 0;
    }

    .dash-header-options-button {
        margin-right: 0;
    }

    .dash-filters-wrapper {
        border-top: 1px solid $grey-border-lightest;
        border-left: 1px solid $grey-border-lightest;
        border-right: 1px solid $grey-border-lightest;
        border-radius: 6px 6px 0 0;
        -webkit-box-shadow: $tile-shadow;
                box-shadow: $tile-shadow;
    }

    .gd-flex-item-stretch {
        border-left: 1px solid $grey-border-lightest;
        border-right: 1px solid $grey-border-lightest;
        -webkit-box-shadow: $tile-shadow;
                box-shadow: $tile-shadow;
        border-radius: 0 0 6px 6px;
    }

    .section-title img {
        width: 12px;
        margin-right: 2px;
    }

    .mobile-actions {
        display: none;
        gap: 20px;
        margin-bottom: 20px;

        img.user-account-purple {
            width: 14px;
            margin-right: 6px;
        }

        img.dashboards {
            width: 12px;
            margin-left: 6px;
        }

        img.dropdown-caret-dark {
            width: 12px;
            margin-left: 6px;
        }

        p {
            margin-top: 10px;
        }

        button img {
            width: 16px;
            margin-right: 6px;
        }

        button {
            width: 100%;
            justify-content: center;
        }
    }

    .gooddata {
        border: 1px solid $grey-lighter;
        border-radius: $border-radius;
        box-shadow: $tile-shadow;
        margin-bottom: 40px;
    }
}

/** Media styles **/

/* Phone */
@media screen and (max-width: $tablet-small) {
    .dashboard {
        .section-title {
            display: none;
        }

        .mobile-actions {
            display: flex;
        }
    }
}

@media (max-width: $mobile) {
    .dashboard {
        .gd-flex-item-stretch {
            border-radius: 3px;
        }

        .dash-header-inner {
            display: none;
        }

        .dash-header .dash-header-options-button{
            margin-top: 8px !important;
        }

        .dash-filters-wrapper, .gd-flex-item-stretch {
            position: absolute;
            left: 5%;
            margin-top: 11px;
            width: 100%;
        }

        .dash-filters-wrapper {
            z-index: 11;
        }
    }
}
