@import '/src/core/scss/all.scss';

.App {
    background-color: $white;
    width: 100%;
    position: absolute;
    top: 166px;
}

@media (max-width: $mobile) {
    .App {
        width: 90%;
        top: 130px;
    }
}
