@import 'metroplex/dist/base/styles/variables/_variables';
@import '/src/core/scss/all.scss';


//-----------------------------------------------
// Account menu
//-----------------------------------------------

.AccountMenu .Dropdown__block {
    overflow: hidden;
    z-index: 1001;
}

.AccountMenu__links {
    margin: 6px 0;
    .Button {
        height: 42px;
        width: 100%;
        justify-content: left;
        text-align: left;
        border: none;
        border-radius: 0;
        font-weight: $weight-semibold;

        &:hover, &:focus {
            color: inherit;
            box-shadow: none;
            background-color: $grey-lightest;
        }
    }

    .Button__icon, .Button__action {
        width: 16px;
        height: 16px;
    }

    .Button__action {
        color: $grey;
    }

    .Button--disabled {
        background-color: transparent;
        color: lighten($grey, 10%);
        text-decoration: line-through;
    }

    .Button--destructive {
        &:hover, &:focus {
            background-color: lighten($negative-lighter, 7%);
        }
    }

    .ActionIcon {
        margin-left: 4px;
        color: $grey-light;
    }
}

.AccountMenu__language {
    padding: 15px;
    border-top: 1px solid #e0e0e0;
}

// @block: account-profile
.account-profile {
    display: flex;
    padding: 16px;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;

    // @element: account-profile__info
    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__name {
        font-size: 14px;
        font-weight: $weight-semibold;
        margin: 0;
    }

    // @element: account-profile__email
    &__email, &__company {
        font-size: 13px;
        margin: 0;
        color: $grey;
    }
}

// @element: account-profile__avatar
.account-profile__avatar {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    color: $grey-dark;
    font-size: $size-large;
    font-weight: $weight-semibold;
    margin-right: $spacer-medium;
    border-radius: 24px;
    background-color: $grey-lighter;
}

// @modifier: select--account-language
:root .select--account-language {
    width: 220px;
}

.Dropdown.Dropdown--topbar {
	.Button--icon {
		width: 36px !important;
	}

    .Dropdown__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px 0;
        color: $grey-darker;
        font-size: $size-medium;
        font-weight: 500;

        p {
            margin: 0 0 4px;
        }
    }

    .Dropdown__body {
        padding: 4px 16px 12px;
        margin: 0;
        border-bottom: 1px solid #e0e0e0;

        .Dropdown--topbar__title {
            color: $grey-darker;
            font-size: $size-medium;
            font-weight: $weight-semibold;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    .Dropdown__menu {
        border-bottom: 1px solid #e0e0e0;

        &:last-child {
            border-bottom: 0;
        }

        li a {
            display: flex;
            align-items: center;
            color: $grey-dark;

            padding: 8px 16px;
            & > svg {
                display: block;
                float: none;
                position: relative;
                top: 1px;
            }
            &:hover {
                color: $primary;
                background-color: $primary-lightest;
            }
            &:active {
                color: $primary;
                background-color: $primary-lighter;
            }

            &.list-current {
                color: $primary;
                background-color: $grey-lightest;
            }
        }
    }

    .Dropdown__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px 12px;
        border-bottom: 1px solid #e0e0e0;

        &:last-child {
            border-bottom: 0;
        }

        .Button {
            border-radius: 3px;
        }
    }
}

@media (max-width: $mobile)
{
    .Dropdown.Dropdown--topbar {
        .Button--icon {
            width: 30px !important;
        }
    }

    .Dropdown__block.Dropdown--topbar {
        width: 270px;
    }
}
