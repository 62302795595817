@import 'metroplex/dist/base/styles/variables/_variables';

.IconCoin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    color: $primary;
    background-color: color-mix(in srgb, currentColor 20%, transparent);

    .Icon {
        width: 24px;
        height: 24px;
    }
}