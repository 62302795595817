@import '/src/core/scss/all.scss';

.PageWrapper {
  position: relative;
  background-size: auto 91px;
  background: $white no-repeat top left 40%;
  top: -100px;
  padding-top: 100px;
}

.BlankFeature {
  &__inner {
    max-width: 316px;

    img {
      height: 149px;
      width: 150px;
    }
  }

  &__title {
    font-size: 20px !important;
    color: $grey-darkest !important;
  }

  &__message p {
    font-size: 14px;
    color: #4D4F5C
  }
}

.Button {
  border-radius: 6px;
  font-size: var(--size-medium);
  font-weight: var(--weight-semibold);
}
