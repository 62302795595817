/* Hamburger SVG icon */
.ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hamRotate.active {
    transform: rotate(45deg);
}

.hamRotate180.active {
    transform: rotate(180deg);
}

.line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: #FFFFFF;
    stroke-width: 5.5;
    stroke-linecap: round;
}

.ham2 .top {
    stroke-dasharray: 40 121;
}

.ham2 .bottom {
    stroke-dasharray: 40 121;
}

.ham2.active .top {
    stroke-dashoffset: -102px;
}

.ham2.active .bottom {
    stroke-dashoffset: -102px;
}