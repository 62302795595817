// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

// Font-family
$font: 'Poppins', -apple-system, BlinkMacSystemFont, ui-sans-serif, system-ui, sans-serif;

// Font sizes
$font-size-h1: 20px;
$font-size-h2: 16px;
$font-size-body: 14px;
$font-size-supporting: 13px;
$font-size-logo: 27px;

$font-size-logo-mobile: 18px;
$font-size-body-mobile: 14px;

// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Font styles
body {
    font-family: $font;
    font-size: $font-size-body;
    font-weight: $font-weight-light;
    color: $grey-dark;
}

h1 {
    font-size: $font-size-h1;
    font-weight: $font-weight-regular;
}

h2 {
    font-size: $font-size-h2;
    font-weight: $font-weight-regular;
}

h3 {
    font-size: $font-size-supporting;
    font-weight: $font-weight-light;    
}


.sub-text {
    font-size: $font-size-supporting;
    font-weight: $font-weight-light;
}

a {
    text-decoration: none;
    color: $grey-dark;
}

