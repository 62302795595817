@import '/src/core/scss/all.scss';

.PageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 50px;
    position: fixed;
    background-size: auto 91px;
    background: $white no-repeat top left 40%;
    border-bottom: 1px solid $grey-lighter;
    width: 100%;
    height: 115px;
    top: 64px;
    z-index: 6000;

    .PageHeader__text {
        color: $grey-dark;
        font-size: var(--size-medium);
    
        h2 {
            color: var(--grey-darker);
            font-size: $font-size-h1;
            font-weight: $font-weight-regular;
            margin-bottom: 6px;
        }
    }
}

@media screen and (max-width: $mobile) {
    .PageHeader {
        line-height: 1;
        padding-left: 5%;
        background: $white no-repeat top right;
        background-size: 44% auto;
        top: 50px;
        padding: 18px 20px;
        height: 72px;

        .PageHeader__text {
            .greeting h2 {
                font-size: 14px;
                font-weight: 500;
            }

            .profile-info {
                font-size: 10px;
                font-weight: 400;
            }
        }
    }
}