// Core
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

img {
    width: 100%;
}

p {
    margin: 0;
    padding: 0;
}

// Buttons
button.primary {
    padding: 12px 14px;
    border-radius: 6px;
    border: 0;
    background: $primary;
    color: $white;
    font-family: $font;
    font-size: $font-size-body;
    font-weight: $font-weight-regular;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    box-shadow: $button-shadow;

    &:hover {
        background: darken($primary, 10%);
    }
}

button.secondary {
    padding: 12px 14px;
    border-radius: 6px;
    border: 1px solid $grey-light;
    background: $white;
    color: $grey-dark;
    font-family: $font;
    font-size: $font-size-body;
    font-weight: $font-weight-regular;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    box-shadow: $button-shadow;

    &:hover {
        background: $grey-lightest;
    }
}

// Containers
.main-container {
    padding: 40px;
    margin: 0 auto;
    max-width: 1600px;
    display: flex;
}

.section-title {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


// Borders
$border-radius: 6px;

/** Media styles **/

/* Phone */
@media screen and (max-width: $tablet-small) {
    .main-container {
        padding: 30px;
    }
}