@import '/src/core/scss/all.scss';

.TopbarSection .Dropdown--impersonation-menu button:not(.ExitImpersonationButton){
    color: $white !important;
    background: none !important;
    font-weight: 500;
    border: none !important;

    .ImpersonationLabel {
        max-width: 250px;
    }

    .caret {
        padding-left: 5px;

        .Icon {
            height: 9px;
            width: 9px;
        }
    }
}

.Dropdown--impersonation-menu {
    &:not(.Dropdown--open, .Dropdown__block--normal) {
        &:hover, &:focus {
            color: #fff;
            border-color: transparent;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 6px;
        }
    }
    
    .Button--topbar {
        display: flex;
        align-items: center;
        color: $grey-border-dark;
        font-weight: 500;
        font-size: 14px;
        background-color: $white;
        border: 1px solid $grey-border-light;
        border-radius: 6px;
        transition: background .15s ease ;

        .ImpersonationLabel {
            overflow: hidden;
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
        }

        .caret {
            .Icon {
                width: var(--spacer-medium);
                height: var(--spacer-medium);
            }
        }
    }

    .Button--topbar:hover {
        border-color: $grey-border;
        background-color: $grey-bg;
    }

    .Dropdown__block {
        width: auto;
        padding: 20px;

        span {
            color: $grey-dark;
            font-weight: 500;
            size: 14px;
            margin-bottom: 10px;
        }

        .ImpersonationList {
            margin-top: 10px;
            border: solid 1px $grey-light;
            padding: 8px 10px 8px 10px;

            width: 400px;
            max-height: 320px;
            overflow-y: scroll;

            .ImpersonationScope {
                color: $primary;
                font-weight: 500;
                background: $primary-light !important;

                .ImpersonationButton .Icon {
                    display: block;
                    color: $primary !important;
                }
            }

            .EstateItem {
                display: flex;
                align-items: center;
                padding: 15px 0px;
                padding: 8px 10px;
                justify-content: space-between;
                height: 35px;

                .EstateLabel {
                    display: flex;
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    .Icon {
                        flex-shrink: 0;
                        width: 18px;
                        height: 18px;
                    }
                }

                p {
                    margin-left: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:hover {
                    background: $primary-light;
                }

                .ImpersonationButton {
                    display: flex !important;
                    opacity: 0;
                    visibility: hidden;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    background-color: $grey-lightest;
                    border: 1px solid $grey-light;
                    border-radius: 3px;
                    transition: opacity 0.1s ease-in-out, visibility 0.1s ease-in-out;

                    .Icon {
                        width: 100%;
                        height: 100%;
                        color: $grey;

                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }

            .EstateItem:hover .ImpersonationButton {
                display: flex !important;
                opacity: 1;
                visibility: visible;
            }

            .ImpersonationScope .ImpersonationButton {
                display: flex !important;
                opacity: 1;
                visibility: visible;
            }
        }

        .Tree .TreeBranch .TreeBranch {
            padding-left: 10px;
        }

        .Dropdown__footer {
            padding: 20px 0px 0px 0px;

            span {
                margin-bottom: 0;
                font-weight: 400;
            }

            .Button {
                font-weight: 400;
                border-radius: 3px;
            }
        }
    }
}

@media (max-width: $mobile)
{
    .MobileDropdown {
        &--title {
            font-size: 10px;
            font-weight: 500;
        }
    }

    .Dropdown--impersonation-menu {
        .Button--topbar {
            font-size: 10px;
            font-weight: 500;

            .Icon {
                margin-right: 0px !important;
            }

            .Button {
                padding-left: 7px !important;
                padding-right: 7px !important;
            }
        }

        .Dropdown__block {
            .ImpersonationList {
                width: 280px;
            }
        }
    }
}
