@import "metroplex/dist/base/styles/variables/_variables";

.AppsMenu {
    padding: 10px 15px;

    &__title {
        color: $grey;
        margin: 4px 0;
        font-size: $size-xsmall;
    }

}

.AppsMenu__button {
    border-color: transparent;
    padding: 8px;
    width: 100%;
    min-width: 210px;
    justify-content: start;
    margin: 2px 0;

    .IconCoin {
        width: 24px;
        height: 24px;

        .Icon {
            width: 12px;
            height: 12px;
        }
    }

    span {
        margin-left: 10px;
        font-weight: $weight-semibold;
    }

    &:hover,
    &--active {
        border-color: $primary;
        background-color: $primary-lightest;
        color: $grey-dark;
    }
}